import { Button, Tooltip, Progress, rem, Text } from "@mantine/core";
import {
  IconCloud,
  IconCheck,
  IconDownload,
  IconCloudCog,
  IconMoodLookDown,
} from "@tabler/icons-react";
import { useEditorState } from "~/components/AppContent/state/EditorContext";
import { checkIfZeroFramesInTimeline } from "~/utils/editor/checkIfZeroFramesInTimeline";
import { useCallback, useEffect } from "react";
import { notifications } from "@mantine/notifications";
import { AnalyticsEventTypes, triggerAnalyticsEvent } from "~/utils/analytics";
import { useFetcher, useRouteLoaderData } from "@remix-run/react";
import { getRoute } from "~/routes";
import { RenderProgress } from "~/components/AppContent/Editor/HeaderControls/RenderProgress";
import { RootLoaderData } from "~/root";

export function RenderVideoButton({ video }) {
  const { editorState } = useEditorState();
  const isZeroFrames = checkIfZeroFramesInTimeline(editorState);
  const fetcher = useFetcher();
  const data = useRouteLoaderData("root") as RootLoaderData;
  const { currentUserPlan } = data;
  const startRender = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.preventDefault();
			const data = new FormData();
			data.append('editorState', JSON.stringify(editorState));
      data.append('currentUserPlan', JSON.stringify(currentUserPlan));
			fetcher.submit(data, { action: getRoute('startRender'), method: 'post' });
		},
		[fetcher, editorState]
	);

  // useEffect(() => {
  //   // console.log("Fetcher state:", fetcher.state);
  //   if (fetcher.data) {
  //     // console.log("Fetcher data:", fetcher.data);
  //   }
  //   if (fetcher.error) {
  //     // console.error("Fetcher error:", fetcher.error);
  //   }
  // }, [fetcher]);
  function handleRender(event) {
    startRender(event);
    triggerAnalyticsEvent(AnalyticsEventTypes.CLICK_RENDER_VIDEO, {
      video: video,
      videoId: video.id,
    });
    notifications.show({
      id: "render-progress",
      title: `Rendering started`,
      icon: <IconCloudCog style={{ width: rem(25), height: rem(25) }} />,
      message: <Progress value={0} size="sm" mt="sm" />,
      autoClose: false,
      color: "transparent",
    });
  }

  // useEffect(() => {
  //   if (isRendering) {
  //     notifications.update({
  //       id: "render-progress",
  //       title: `Rendering in progress ${Math.round(progress * 100)}%`,
  //       icon: <IconCloudCog style={{ width: rem(25), height: rem(25) }} />,
  //       color: "transparent",
  //       message: (
  //         <>
  //           <Progress value={progress * 100} size="sm" mt="sm" />
  //         </>
  //       ),
  //     });
  //   } else if (state.status === "done") {
  //     triggerAnalyticsEvent(AnalyticsEventTypes.RENDER_VIDEO_DONE, {
  //       video: video,
  //       videoId: video.id,
  //     });
  //     notifications.update({
  //       id: "render-progress",
  //       title: "Rendering complete",
  //       message: (
  //         <>
  //           <Text size={"sm"}>Your video is ready.</Text>
  //           <Button
  //             component="a"
  //             href={state.url}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             leftSection={<IconDownload size={14} />}
  //             size="sm"
  //             mt="sm"
  //           >
  //             Download Video
  //           </Button>
  //         </>
  //       ),
  //       icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
  //       color: "teal",
  //     });
  //   } else if (state.status === "error") {
  //     triggerAnalyticsEvent(
  //       AnalyticsEventTypes.RENDER_VIDEO_ERROR,
  //       {
  //         video: video,
  //         videoId: video.id,
  //         error: state.message,
  //       },
  //       "error"
  //     );
  //     notifications.update({
  //       title: "Failed to render the video",
  //       message: state.message,
  //       color: "red",
  //       autoClose: false,
  //       icon: <IconMoodLookDown size="1.1rem" />,
  //     });
  //   }
  // }, [isRendering, progress, state.status, state.url]);
  // console.log("fetcher: ", fetcher);
  return (
    <>
     {fetcher.data && (
        <RenderProgress
          bucketName={fetcher.data.bucketName}
          renderId={fetcher.data.renderId}
          video={video}
        />
      )}
      <Tooltip
        label={
          isZeroFrames
            ? "No frames added. Nothing to render ¯\\_(ツ)_/¯"
            : "Turn this video into a downloadable .mp4 file."
        }
        position={"bottom-start"}
      >
        <Button
          variant="filled"
          size="sm"
          leftSection={<IconCloud size="1rem" />}
          onClick={handleRender}
          disabled={isZeroFrames}
          // loading={isRendering}
        >
          Render
        </Button>
      </Tooltip>
      {/*<Button variant="filled" size="sm" onClick={undo} disabled={!isRendering}>*/}
      {/*  Cancel render*/}
      {/*</Button>*/}
    </>
  );
}
