import {
  Group,
  Text,
  useMantineColorScheme,
  Button,
  Menu,
} from "@mantine/core";
import { Link, useLoaderData, useLocation, useMatches } from "@remix-run/react";
import { getRoute } from "~/routes";
import { constants } from "~/constants";
import { useTranslation } from "react-i18next";
import { UserDropdown } from "./UserDropdown";
import {
  IconHome,
  IconUsers,
  IconSparkles,
  IconChevronDown,
  IconDiamond,
} from "@tabler/icons-react";
import { LogoButton } from "~/components/AppShell/AppHeader/LogoButton";
import { MyVideosMenu } from "~/components/AppShell/AppHeader/MyVideosMenu";
import { loader as rootLoader } from "~/root";
import { NavAuthButtons } from "~/components/AppShell/AppHeader/NavAuthButtons";
import { CreateVideoButton } from "~/components/AppContent/CreateNewVideoButton/CreateVideoButton";
import { EditorElements } from "~/components/AppShell/AppHeader/EditorElements";
import { checkIsOnEditorPage } from "~/utils/checkIsOnEditorPage";
import { checkIfUserFree } from "~/utils/checkIfUserFree";

export default function AppHeader() {
  const { t } = useTranslation();

  const { user, videos, currentUserPlan } = useLoaderData<typeof rootLoader>();
  // Use useMatches to get the current route's loader data
  const matches = useMatches();
  const { isOnEditorPage, videoRoute } = checkIsOnEditorPage(matches);
  const videoData = videoRoute?.data?.videoFromLoader;

  const location = useLocation();

  const isOnPlanPage = location.pathname.includes(getRoute("plan"));

  let isUserFree = checkIfUserFree(currentUserPlan);
  // if (currentUserPlan === undefined) {
  //   isUserFree = true;
  // }

  const shouldDisplayEditorControls = isOnEditorPage;
  const shouldDisplayCreateVideoButton = !isOnEditorPage && !isOnPlanPage;
  const shouldDisplayUpgradeButton =
    isUserFree && !isOnPlanPage && !isOnEditorPage;

  const NavButton = ({ label, route, icon: Icon }) => (
    <Button
      component={Link}
      to={route}
      variant="subtle"
      className={"text-color"}
      leftSection={<Icon size={"1rem"} />}
    >
      {label}
    </Button>
  );

  return (
    <Group justify="space-between" align="center" h="100%" px="md">
      <Group>
        <div className="logo-button-container">
        <LogoButton />
        </div>

        <div className="home-container">
          <NavButton label={t("home")} route={getRoute("home")} icon={IconHome} />
        </div>
        {videos && videos.length > 1 && <MyVideosMenu />}
      </Group>
      <Group>
        {user ? (
          <>
            {shouldDisplayUpgradeButton && (
              <Button
                variant="light"
                size="sm"
                component={Link}
                to={getRoute("plan")}
                rightSection={<IconDiamond size="1rem" />}
                // onClick={handleCreateVideo}
                // disabled={navigation.state === "loading"}
                // loading={fetcher.state === "submitting"}
              >
                Buy LTD 
              </Button>
            )}
            {shouldDisplayCreateVideoButton && (
              <CreateVideoButton></CreateVideoButton>
            )}

            {shouldDisplayEditorControls && (
              <EditorElements video={videoData} />
            )}
            <UserDropdown />
          </>
        ) : (
          <NavAuthButtons></NavAuthButtons>
        )}
      </Group>
    </Group>
  );
}
