// app/components/AppContent/Editor/AspectRatioSelector.tsx

import React from "react";
import { ActionIcon, Tooltip } from "@mantine/core";
import {
  IconDeviceLaptop,
  IconDeviceMobile,
  IconSquare,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useEditorState, useEditorSync }  from "~/components/AppContent/state/EditorContext";
import { rem } from "@mantine/core";

export function AspectRatioSelector({ video }) {
  let { t } = useTranslation();
  const { editorState, setEditorState } = useEditorState();
  const { isSaving } = useEditorSync();

  if (!editorState || !video) {
    return null;
  }

  const aspectRatios = [
    {
      value: "horizontal",
      icon: IconDeviceLaptop,
      label: "Laptop 16:9 (for YouTube)",
    },
    {
      value: "vertical",
      icon: IconDeviceMobile,
      label: "Mobile 9:16 (for TikTok, Instagram)",
    },
    // {
    //   value: "square",
    //   icon: IconSquare,
    //   label: "Universal 1:1 (for 𝕏, LinkedIn)",
    // },
  ];

  const handleAspectRatioChange = (value: string) => {
    if (value !== editorState.aspectRatio) {
      setEditorState((prevState) => ({
        ...prevState,
        aspectRatio: value,
      }));
    }
  };

  return (
      // <Tooltip label="Vertical and square videos are coming in Q1 2025.">
    <ActionIcon.Group>
      {aspectRatios.map((ratio, key) => (
        // <Tooltip key={key} position="bottom" label={ratio.label}>
          <ActionIcon
            key={key}
            variant={
              editorState.aspectRatio === ratio.value ? "filled" : "default"
            }
            size="lg"
            aria-label={ratio.label}
            onClick={() => handleAspectRatioChange(ratio.value)}
          >
            <ratio.icon style={{ width: rem(16), height: rem(16) }} />
          </ActionIcon>
        /* </Tooltip> */
      ))}
    </ActionIcon.Group>
      // </Tooltip>
  );
}
