import { useEffect, useRef } from "react";
import { notifications } from "@mantine/notifications";
import { Progress, Button, Text, rem } from "@mantine/core";
import { IconCloudCog, IconCheck, IconDownload, IconMoodLookDown } from "@tabler/icons-react";
import { usePollRenderStatus } from "~/components/remotion/rendering/use-poll-render-status";
import { AnalyticsEventTypes, triggerAnalyticsEvent } from "~/utils/analytics";

interface Props {
  renderId: string;
  bucketName: string;
  video: any;
}

export const RenderProgress = ({ renderId, bucketName, video }: Props) => {
  const { progress } = usePollRenderStatus({
    renderId,
    bucketName,
    videoId: video.id,
  });
  const hasShownError = useRef(false);

  const truncatedTitle =
      video.title.length > 20
        ? video.title.slice(0, 20) + "..."
        : video.title;

  useEffect(() => {
    if (!progress) {
      hasShownError.current = false; // Reset error state when starting new render
      notifications.show({
        id: "render-progress",
        title: "Invoking render",
        message: "Preparing to start the rendering process...",
        loading: true,
        autoClose: false,
      });
    } else if (progress.errors.length > 0 && !hasShownError.current) {
      hasShownError.current = true;
      triggerAnalyticsEvent(
        AnalyticsEventTypes.RENDER_VIDEO_ERROR,
        {
          video: video,
          videoId: video.id,
          error: progress.errors[0].message,
        },
        "error"
      );
      console.log("progress.errors: ", progress.errors);
      notifications.update({
        id: "render-progress",
        title: "Failed to render the video",
        message: progress.errors[0].message,
        color: "red",
        autoClose: false,
        icon: <IconMoodLookDown size="1.1rem" />,
      });
    } else if (progress.outputFile) {
      hasShownError.current = false; // Reset error state on successful completion
      triggerAnalyticsEvent(AnalyticsEventTypes.RENDER_VIDEO_DONE, {
        video: video,
        videoId: video.id,
      });
      notifications.update({
        id: "render-progress",
        title: "Rendering complete",
        message: (
          <>
            <Text size="sm">"{truncatedTitle}" is ready.</Text>
            <Button
              component="a"
              href={progress.outputFile}
              target="_blank"
              rel="noopener noreferrer"
              leftSection={<IconDownload size={14} />}
              size="sm"
              mt="sm"
            >
              Download Video
            </Button>
          </>
        ),
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        color: "teal",
        autoClose: false,
      });
    } else if (!hasShownError.current) { // Only show progress if we haven't shown an error
      notifications.update({
        id: "render-progress",
        title: `Rendering "${truncatedTitle}": ${Math.round(progress.overallProgress * 100)}%`,
        icon: <IconCloudCog style={{ width: rem(25), height: rem(25) }} />,
        color: "transparent",
        message: (
          <Progress value={progress.overallProgress * 100} size="sm" mt="sm" />
        ),
        autoClose: false,
      });
    }
  }, [progress, video]);

  return null; // This component doesn't render anything visible
};